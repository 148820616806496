<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="1" x2="0.325" y1="-0.125" y2="1.069">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_trousers" transform="translate(-810 -2975)">
    <rect :fill="background" data-name="Rectangle 216" height="183" id="Rectangle_216" transform="translate(810 2975)" width="183"/>
    <g id="trousers" style="isolation: isolate" transform="translate(877.867 3020.712)">
     <path d="M0,118.529H15.1l4.869-63.361a1.664,1.664,0,0,1,3.318,0l4.878,63.361h15.1V41.851A11.648,11.648,0,0,1,33.414,32H29.952v8.32a4.992,4.992,0,0,1-4.992,4.992H21.632a1.664,1.664,0,0,1-1.664-1.664V32H9.851A11.648,11.648,0,0,1,0,41.851Zm0,0" data-name="Path 498" fill="url(#linear-gradient)" id="Path_498" transform="translate(0 -25.344)"/>
     <path d="M0,0H19.968V3.328H0ZM0,0" data-name="Path 499" fill="url(#linear-gradient)" id="Path_499"/>
     <path d="M0,38.489A8.345,8.345,0,0,0,6.489,32H0Zm0,0" data-name="Path 500" fill="url(#linear-gradient)" id="Path_500" transform="translate(0 -25.344)"/>
     <path d="M144,0h13.312V3.328H144Zm0,0" data-name="Path 501" fill="url(#linear-gradient)" id="Path_501" transform="translate(-114.048)"/>
     <path d="M183.29,32H176.8a8.345,8.345,0,0,0,6.489,6.489Zm0,0" data-name="Path 502" fill="url(#linear-gradient)" id="Path_502" transform="translate(-140.026 -25.344)"/>
     <path d="M112,0h3.328V3.328H112Zm0,0" data-name="Path 503" fill="url(#linear-gradient)" id="Path_503" transform="translate(-88.704)"/>
     <path d="M115.328,40.32V32H112v9.984h1.664A1.664,1.664,0,0,0,115.328,40.32Zm0,0" data-name="Path 504" fill="url(#linear-gradient)" id="Path_504" transform="translate(-88.704 -25.344)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>